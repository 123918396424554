<template>
  <div class="mark-message">
    <countPage
      padding="5px 10px"
      margin="0 0 10px 0"
      borderRadius="5px"
      :content="`查询到标记的消息${total}条`"
      style="box-shadow:0 1px 3px 0 #d8d9d9;"
    />
    <div
      class="account-center"
      :style="{ background: list.length == 0 ? 'white' : '' }"
      v-loading="loading"
    >
      <div
        class="right-box"
        id="t-scroll-mark"
        v-infinite-scroll="getTelegramUsersSearch"
        :infinite-scroll-disabled="scrollDisabled"
        :infinite-scroll-distance="10"
      >
        <div class="item-box" v-for="(item, i) in list" :key="i">
          <img
            class="left-img"
            :src="
              item.user_photo
                ? 'data:image/png;base64,' + item.user_photo
                : require('@/assets/imgs/p.png')
            "
            alt=""
          />
          <div>
            <div class="top">
              <el-popover
                v-if="item.user_id"
                placement="bottom"
                width="400"
                trigger="hover"
              >
                <div class="el-popover-child">
                  <el-descriptions
                    :column="1"
                    :labelStyle="{
                      width: '60px',
                      textAlign: 'right',
                      color: '#060606'
                    }"
                  >
                    <el-descriptions-item label="用户ID">
                      {{ item.user_id }}
                    </el-descriptions-item>
                    <el-descriptions-item label="用户名">
                      {{ item.user_username }}
                    </el-descriptions-item>
                    <el-descriptions-item label="姓">
                      {{ item.user_first_name }}
                    </el-descriptions-item>
                    <el-descriptions-item label="名">
                      {{ item.user_last_name }}
                    </el-descriptions-item>
                    <el-descriptions-item label="账号检索">
                      <span
                        class="mark-message-retrieval-span"
                        @click="accountRetrieval(item.user_id)"
                      >
                        检索
                      </span>
                    </el-descriptions-item>
                    <el-descriptions-item label="手机号">
                      <span
                        style=" padding: 0 10px;height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                        @click="userDetail(2, item.user_id)"
                      >
                        查询
                      </span>
                      <span
                        v-if="userInfo.isYs == 0"
                        style=" margin-left: 10px;padding: 0 10px; height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                        @click="userDetail(1, item.user_id)"
                      >
                        查询归属地
                      </span>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item
                      label="归属地"
                      v-if="userInfo.isYs == 0"
                    >
                      <span
                        style=" padding: 0 10px; height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                        @click="userDetail(1, item.user_id)"
                      >
                        查询归属地
                      </span>
                    </el-descriptions-item> -->
                  </el-descriptions>
                  <div class="popover-btn-box">
                    <el-button size="small" @click="clearRemarkFun(item.id)">
                      取消标记
                    </el-button>
                    <el-button size="small" @click="remarkAccountFun(item)">
                      标记该账号
                    </el-button>
                    <el-button size="small" @click="shieldAccountFun(item)">
                      屏蔽该账号
                    </el-button>
                    <el-button size="small" @click="locationGroup(item)">
                      定位到群组
                    </el-button>
                  </div>
                </div>
                <span slot="reference">
                  <span
                    class="top-1"
                    v-if="item.user_first_name || item.user_last_name"
                  >
                    {{ item.user_first_name }}&nbsp; {{ item.user_last_name }}
                  </span>
                  <span v-else class="top-1">
                    {{ item.user_id }}
                  </span>
                </span>
              </el-popover>
              <span
                class="top-2"
                style="margin-right:10px"
                v-if="item.user_first_name || item.user_last_name"
              >
                ID：{{ item.user_id }}
              </span>
              <span class="top-2">{{ item.message_date }}</span>
            </div>
            <div class="content">
              <span v-html="item.message_text"></span>
              <!--转发信息  -->
              <span class="content-qun" v-if="item.message_fwd_from_id">
                <el-popover
                  placement="bottom"
                  width="300"
                  trigger="hover"
                  v-if="item.forward_user.userId"
                >
                  <div>
                    <el-descriptions
                      :column="1"
                      :labelStyle="{
                        width: '60px',
                        textAlign: 'right',
                        color: '#060606'
                      }"
                    >
                      <el-descriptions-item label="用户ID">
                        {{
                          item.forward_user.userId
                            ? item.forward_user.userId
                            : ''
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="用户名">
                        {{
                          item.forward_user.username
                            ? item.forward_user.username
                            : ''
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="姓">
                        {{
                          item.forward_user.userFirstName
                            ? item.forward_user.userFirstName
                            : ''
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="名">
                        {{
                          item.forward_user.userLastName
                            ? item.forward_user.userLastName
                            : ''
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="账号检索">
                        <span
                          class="mark-message-retrieval-span"
                          @click="accountRetrieval(item.forward_user.userId)"
                        >
                          检索
                        </span>
                      </el-descriptions-item>
                      <el-descriptions-item label="手机号">
                        <span
                          style=" padding: 0 10px;height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                          @click="userDetail(2, item.forward_user.userId)"
                        >
                          查询
                        </span>
                      </el-descriptions-item>
                      <el-descriptions-item
                        label="归属地"
                        v-if="userInfo.isYs == 0"
                      >
                        <span
                          style=" padding: 0 10px; height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                          @click="userDetail(1, item.forward_user.userId)"
                        >
                          查询归属地
                        </span>
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
                  <span slot="reference">
                    转自：{{ item.message_fwd_from_id }}
                  </span>
                </el-popover>
                <span v-else>转自：{{ item.message_fwd_from_id }}</span>
              </span>
              <!-- 群信息 -->
              <span class="content-qun" v-if="item.channel_title">
                <el-popover placement="bottom" width="300" trigger="hover">
                  <div>
                    <el-descriptions
                      :column="1"
                      :labelStyle="{
                        width: '60px',
                        textAlign: 'right',
                        color: '#060606'
                      }"
                    >
                      <el-descriptions-item label="群名">
                        {{ item.channel_title }}
                      </el-descriptions-item>
                      <el-descriptions-item label="群号">
                        {{ item.channel_id }}
                      </el-descriptions-item>
                      <el-descriptions-item label="群用户名">
                        {{ item.channel_username }}
                      </el-descriptions-item>

                      <el-descriptions-item label="群简介">
                        {{ item.channel_summary }}
                      </el-descriptions-item>
                      <el-descriptions-item label="群检索">
                        <span
                          class="mark-message-retrieval-span"
                          @click="retrieval(item)"
                        >
                          检索
                        </span>
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
                  <span slot="reference">来自群：{{ item.channel_title }}</span>
                </el-popover>
              </span>
            </div>
            <div
              class="content"
              v-if="item.message_audio_url && item.message_audio_url !== ''"
            >
              <audio controls>
                <source :src="item.message_audio_url" type="audio/ogg" />
                <source :src="item.message_audio_url" type="audio/mpeg" />
              </audio>
            </div>
            <div
              class="content"
              v-if="item.message_img_url && item.message_img_url !== ''"
            >
              <img :src="item.message_img_url" alt="" />
            </div>
            <div
              class="content"
              v-if="item.message_video_url && item.message_video_url !== ''"
            >
              <video controls :src="item.message_video_url"></video>
            </div>
            <!-- <el-popover
              placement="bottom"
              trigger="hover"
              popper-class="mark-message-popover"
            >
              <div class="btn-box">
                <el-button type="text" @click="clearRemarkFun(item.id)">
                  取消标记
                </el-button>
                <el-button
                  style="margin:0"
                  type="text"
                  @click="remarkAccountFun(item)"
                >
                  标记该账号
                </el-button>
                <el-button
                  style="margin:0"
                  type="text"
                  @click="shieldAccountFun(item)"
                >
                  屏蔽该账号
                </el-button>
                <el-button
                  style="margin:0"
                  type="text"
                  @click="locationGroup(item)"
                >
                  定位到群组
                </el-button>
              </div>

              <img
                class="mark-news-more"
                slot="reference"
                :src="require('@/assets/imgs/i5.png')"
                alt=""
              />
            </el-popover> -->
          </div>
        </div>
        <li v-if="dataLoading">
          <span v-if="list.length > 0">加载中...</span>
        </li>
        <li v-else>
          <span v-if="list.length > 0">没有更多了...</span>
        </li>
      </div>
      <noData v-show="list.length == 0" />
    </div>

    <!-- 归属地查询 -->
    <userDetail
      ref="userDetailDialog"
      :placeVisible="placeVisible"
      @closePlace="closePlace"
    />
  </div>
</template>

<script>
import { userRemark } from '@/api/mark/markAccount'
import {
  remarkMessage,
  clearRemark,
  userBlacklistApi
} from '@/api/mark/markAccount'
import { scrollTopFun } from '@/plugins/logFunction'
export default {
  name: 'MarkInfo',
  data() {
    return {
      searchForm: {},
      params: {},
      current: 1,
      limit: 50,
      total: 0,
      list: [],
      dataLoading: true, //是否还有更多数据
      loading: false, //加载状态
      scrollDisabled: false,
      placeVisible: false,
      listScrollTop: 0
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  created() {},
  activated() {
    scrollTopFun('t-scroll-mark', 'set', this.listScrollTop)
  },
  deactivated() {
    this.listScrollTop = scrollTopFun('t-scroll-mark', 'get')
  },
  methods: {
    getTelegramUsersSearch() {
      this.loading = true
      this.scrollDisabled = true
      this.params = Object.assign({}, this.searchForm)
      return new Promise((resolver, reject) => {
        userRemark(this.current, this.limit, { remarkType: 1 }).then(data => {
          this.loading = false
          this.total = data.all_count
          if (data.items.length > 0) {
            this.scrollDisabled = false
            this.list = this.list.concat(data.items)
            this.current++
            if (data.items.length < this.limit) {
              this.dataLoading = false
            }
          } else {
            this.dataLoading = false
          }
          resolver()
        })
      })
    },
    closePlace() {
      this.placeVisible = false
    },
    userDetail(type, uid) {
      let that = this
      this.$confirm(
        `继续查询将消耗${this.userInfo.isYs == 0 ? '积分' : '次数'}，是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$refs.userDetailDialog.type = type
          this.$refs.userDetailDialog.uid = uid
          this.$refs.userDetailDialog.getBlastUid().then(data => {
            that.placeVisible = true
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 定位到群组
    locationGroup(row) {
      console.log(row)
      this.$router.push(
        '/group/detail/' + row.channel_id + '?messageId=' + row.message_id
      )
    },
    // 群检索
    retrieval(row) {
      this.$router.push('/group/detail/' + row.channel_id)
    },
    // 账号检索
    accountRetrieval(id) {
      this.$router.push('/user/detail/' + id)
    },
    // 取消标记该消息
    clearRemarkFun(id) {
      clearRemark(id).then(data => {
        this.$message({
          type: 'success',
          message: '已取消标记！'
        })
        this.scrollDisabled = true
        this.list = []
        this.current = 1
        this.getTelegramUsersSearch().then(() => {
          this.scrollDisabled = false
        })
      })
    },
    // 标记该账号
    remarkAccountFun(row) {
      remarkMessage({
        uid: row.user_id,
        remarkType: 2
      }).then(data => {
        this.$message({
          type: 'success',
          message: '账号标记成功！'
        })
      })
    },
    // 屏蔽该账号
    shieldAccountFun(row) {
      this.$confirm('确认屏蔽, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          userBlacklistApi({ tgid: row.user_id }).then(() => {
            this.$message({
              type: 'success',
              message: '屏蔽成功！'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消。'
          })
        })
    }
  }
}
</script>

<style lang="scss">
.mark-message {
  width: 100%;
  height: calc(100vh - 80px);
  background: #f0f2f5;
  padding: 10px;
  .account-center {
    width: 100%;
    height: calc(100% - 40px);
    background: #ffffff;
    // border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    border-radius: 5px;

    // overflow: hidden;
    .right-box {
      width: 100%;
      height: 100%;
      overflow: auto;

      .item-box {
        width: 100%;
        overflow: hidden;
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid #f2f4f8;
        margin-bottom: 10px;
        padding: 13px 10px;
        position: relative;
        .mark-news-more {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          width: 25px;
          display: none;
          cursor: pointer;
        }
        &:hover {
          .mark-news-more {
            display: block;
          }
        }
        .left-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        > div {
          width: calc(100% - 50px);
          margin-left: 10px;

          .top {
            white-space: nowrap;
            .top-1 {
              font-size: 13px;
              margin-right: 15px;
              font-weight: 400;
              color: #4c4c4e;
              line-height: 18px;
              cursor: pointer;
              &:hover {
                color: #0084ff;
              }
            }
            .top-2 {
              font-size: 12px;
              color: #666666;
              line-height: 18px;
            }
          }
          .content {
            font-size: 14px;
            margin-top: 5px;
            font-weight: 300;
            color: #030303;
            line-height: 18px;
            padding-right: 10px;

            .content-qun {
              font-size: 12px;
              color: #666666;
              line-height: 18px;
              margin-top: 5px;
              margin-left: 20px;
              cursor: pointer;
              &:hover {
                color: #0084ff;
              }
            }
            video {
              width: 500px;
              height: 300px;
            }
            img {
              width: 300px !important;
              height: auto !important;
            }
          }
        }
      }
    }
    li {
      list-style: none;
      text-align: center;
      color: #555555;
      font-size: 12px;
    }
  }
}
.mark-message-retrieval-span {
  text-decoration: underline;
  color: #0079d3;
  user-select: none;
  cursor: pointer;
}
.mark-message-popover {
  width: 100px !important;
  min-width: 50px !important;
  .btn-box {
    text-align: center;
  }
}
</style>
